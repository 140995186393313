import { EditorSDK, PageRef, PageData } from '@wix/platform-editor-sdk';
import { APP_TOKEN, SANTA_MEMBERS_APP_ID } from '../constants';

interface BaseParams {
  editorSDK: EditorSDK;
}

interface PageParams {
  pageRef: PageRef;
}

export async function updatePageData({
  editorSDK,
  pageRef,
  pageData,
}: BaseParams & PageParams & { pageData: Partial<PageData> }) {
  await editorSDK.pages.data.update(APP_TOKEN, { pageRef, data: { ...pageData, indexable: false } });
}

export async function rename({ editorSDK, pageRef, title }: BaseParams & PageParams & { title: string }) {
  await editorSDK.pages.rename(APP_TOKEN, { pageRef, title });
}

export async function updatePagePassword({
  editorSDK,
  pageRef,
  plainPassword,
}: BaseParams & PageParams & { plainPassword: string }) {
  await editorSDK.pages.permissions.updatePagePassword(APP_TOKEN, { pageRef, plainPassword });
}

export async function removePagePassword({ editorSDK, pageRef }: BaseParams & PageParams) {
  await editorSDK.pages.permissions.removePagePassword(APP_TOKEN, { pageRef });
}

export async function addPage({
  editorSDK,
  pageTitle,
  pageUriSEO,
}: BaseParams & { pageTitle: string; pageUriSEO: string }) {
  return editorSDK.pages.add(APP_TOKEN, {
    title: pageTitle,
    shouldAddMenuItem: true,
    definition: {
      data: {
        hidePage: false,
        managingAppDefId: SANTA_MEMBERS_APP_ID,
        pageUriSEO,
      },
    },
  });
}

export async function navigateToPageRef({ editorSDK, pageRef }: BaseParams & PageParams) {
  await editorSDK.pages.navigateTo(APP_TOKEN, { pageRef });
}

export function getPageData({ editorSDK, pageRef }: BaseParams & PageParams) {
  return editorSDK.pages.data.get(APP_TOKEN, { pageRef });
}

export function getCurrentPage({ editorSDK }: BaseParams) {
  return editorSDK.pages.getCurrent(APP_TOKEN);
}

export function getHomePage({ editorSDK }: BaseParams) {
  return editorSDK.pages.getHomePage(APP_TOKEN);
}

export function remove({
  editorSDK,
  pageRef,
  shouldShowEditorRemovePanel,
  pageToNavigateAfterRemove,
}: {
  editorSDK: EditorSDK;
  pageRef: PageRef;
  shouldShowEditorRemovePanel: boolean;
  pageToNavigateAfterRemove?: PageRef;
}) {
  return editorSDK.document.pages.remove(APP_TOKEN, {
    pageRef,
    shouldShowEditorRemovePanel,
    pageToNavigateAfterRemove,
  });
}

export function getAllPages({ editorSDK }: BaseParams) {
  return editorSDK.document.pages.data.getAll(APP_TOKEN);
}

export async function getMainMembersPageRef({ editorSDK }: BaseParams) {
  const allPages = await editorSDK.document.pages.data.getAll(APP_TOKEN);
  const mainPage = allPages.find((page) => page.tpaPageId === 'member_info');
  return mainPage && { id: mainPage.id! };
}

export function getApplicationPages({ editorSDK }: { editorSDK: EditorSDK }) {
  return editorSDK.pages.getApplicationPages(APP_TOKEN);
}
