import { EditorSDK, RouterData } from '@wix/platform-editor-sdk';
import { log } from '../../utils/monitoring';
import * as routersService from '../services/routers';
import { shouldFixCorruptedRouterPatterns } from '../../utils/experiments';
import { getRouterRefByPrefix, updateRouterConfig } from '../wrappers/routers';

async function maybeFixRouterPatterns(editorSDK: EditorSDK, router: RouterData) {
  const routerPages = router.pages;
  const routerPatterns = router.config?.patterns ?? {};
  const routerPatternsKeys = Object.keys(routerPatterns);
  const missingPagesPatternsKeys = routerPatternsKeys.filter((key) => {
    const pattern = routerPatterns[key];
    return !routerPages.find((page) => page.pageRoles.includes(pattern.page));
  });

  if (missingPagesPatternsKeys.length > 0) {
    log('Router has corrupted patterns without pages', { extra: { missingPagesPatternsKeys, routerPatterns } });
    const isEnabled = await shouldFixCorruptedRouterPatterns();

    if (!isEnabled) {
      return;
    }

    missingPagesPatternsKeys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete router.config.patterns[key];
    });

    const routerRef = await getRouterRefByPrefix({ editorSDK, prefix: router.prefix });
    await updateRouterConfig({ editorSDK, config: router.config, routerRef });
  }
}

export async function maybeFixRouterConfigurations(editorSDK: EditorSDK) {
  try {
    const { privateRouter, publicRouter } = await routersService.getMembersAreaRouters(editorSDK);
    await maybeFixRouterPatterns(editorSDK, privateRouter);
    await maybeFixRouterPatterns(editorSDK, publicRouter);
  } catch (e) {
    log(`maybeFixRouterConfigurations error: ${e}`);
  }
}
