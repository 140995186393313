// Old sites pages does not have managingAppDefId, which is needed for platformised pages panel
import { EditorReadyOptions, EditorSDK, PageRef } from '@wix/platform-editor-sdk';
import * as routersWrapper from '../wrappers/routers';
import * as pagesService from '../services/pages';
import { log } from '../../utils/monitoring';

export async function maybeSetManagingAppDefIdForMAPages({
  editorSDK,
  options,
}: {
  editorSDK: EditorSDK;
  options: EditorReadyOptions;
}) {
  if (options.firstInstall) {
    return;
  }

  try {
    const routers = await routersWrapper.getAll(editorSDK);
    const pagesRefs = routers.reduce<PageRef[]>((acc, router) => [...acc, ...router.pages.map((p) => p.pageRef)], []);
    await Promise.all(pagesRefs.map((pageRef) => pagesService.updatePageWithManagingAppDefId({ editorSDK, pageRef })));
  } catch (e) {
    log('Failed to set managingAppDefId for MA pages, reason:', e.toString());
  }
}
