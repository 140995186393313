import { instanceValue } from '@wix/wix-instance-parser-js/dist/src';
import { EditorSDK } from '@wix/platform-editor-sdk';
import webBiLogger from '@wix/web-bi-logger';
import { Logger } from '@wix/web-bi-logger/dist/src/types';
import {
  menuToggle,
  membersAreaInstallationError,
  addPageButtonClickMembersPages,
  loadMembersCustomPages,
  verticalTriggeredMaInstallInitiated,
  verticalTriggeredMaInstallSuccess,
  maPageInstalled,
  managePagesActionDelete,
  managePagesAddMemberPagesClick,
  managesPagesModalTabNavigation,
  membersProfileSelection,
} from '@wix/bi-logger-members-app-users/v2';
import {
  menuToggleParams,
  addPageButtonClickMembersPagesParams,
  loadMembersCustomPagesParams,
  membersProfileSelectionParams,
  maPageInstalledParams,
  verticalTriggeredMaInstallSuccessParams,
  verticalTriggeredMaInstallInitiatedParams,
} from '@wix/bi-logger-members-app-users/v2/types';

import { SANTA_MEMBERS_APP_ID } from '../editor-app-module/constants';
import { log } from './monitoring';

const createBIService = async ({ editorSDK, withClientId }: { editorSDK: EditorSDK; withClientId?: boolean }) => {
  const biService: BIService = new BIService();
  await biService.init(editorSDK, withClientId);
  return biService;
};

interface BaseProps {
  app_id?: string;
  instance_id?: string;
  biToken?: string;
  client_id?: string;
}

class BIService {
  logger: Logger;
  baseProps: BaseProps = {};

  constructor() {
    this.logger = webBiLogger.factory().logger();
  }

  init = async (editorSDK: EditorSDK, withClientId: boolean = true) => {
    const instance = await editorSDK.info.getAppInstance('');

    if (!instance) {
      log('missing instance in platform however the editor script will proceed');
    }

    const instanceId = instance ? instanceValue.getValueFromInstance('instanceId', instance) : null;
    const biToken = instance ? instanceValue.getValueFromInstance('biToken', instance) : null;
    this.baseProps = { app_id: SANTA_MEMBERS_APP_ID, instance_id: instanceId, biToken };
    if (withClientId) {
      this.baseProps.client_id = instance ? instanceValue.getValueFromInstance('uid', instance) : null;
    }
  };

  logMembersMenuToggle = ({ pageId, pageName, toggle_status, is_social }) => {
    const toggleName = 'Members Menu';
    const props = { ...this.baseProps, pageId, pageName, toggleName, toggle_status, is_social };
    return this.logger.report(menuToggle(props));
  };

  logLoginMenuToggle = ({ pageId, pageName, toggle_status, is_social }: Partial<menuToggleParams>) => {
    const toggleName = 'Login Menu';
    const props = { ...this.baseProps, pageId, pageName, toggleName, toggle_status, is_social };
    return this.logger.report(menuToggle(props));
  };

  logInstallationFailure = (errorMsg: string) => {
    const props = { ...this.baseProps, errorMsg, appInstanceId: this.baseProps.app_id };
    delete props.app_id;
    return this.logger.report(membersAreaInstallationError(props));
  };

  addPageButtonClick = ({ origin, pageName, pageId, appId }: Partial<addPageButtonClickMembersPagesParams>) => {
    return this.logger.report(addPageButtonClickMembersPages({ ...this.baseProps, origin, pageName, pageId, appId }));
  };

  loadMembersCustomPages = ({ pageName, pageId, appId }: Partial<loadMembersCustomPagesParams>) => {
    return this.logger.report(loadMembersCustomPages({ ...this.baseProps, pageName, pageId, appId }));
  };

  verticalTriggeredMaInstallInitiated = ({ originAppId }: Partial<verticalTriggeredMaInstallInitiatedParams>) => {
    return this.logger.report(verticalTriggeredMaInstallInitiated({ ...this.baseProps, originAppId }));
  };

  verticalTriggeredMaInstallSuccess = ({ originAppId }: Partial<verticalTriggeredMaInstallSuccessParams>) => {
    return this.logger.report(verticalTriggeredMaInstallSuccess({ ...this.baseProps, originAppId }));
  };

  maPageInstalled = ({ originAppId, pageName }: Partial<maPageInstalledParams>) => {
    return this.logger.report(maPageInstalled({ ...this.baseProps, originAppId, pageName }));
  };

  managePagesActionDelete = ({ pageId, pageName, value, appId }) => {
    return this.logger.report(managePagesActionDelete({ ...this.baseProps, pageId, pageName, value, appId }));
  };

  managePagesAddMemberPagesClick = ({ origin }) => {
    return this.logger.report(managePagesAddMemberPagesClick({ ...this.baseProps, origin }));
  };

  managesPagesModalTabNavigation = ({ tabName }) => {
    return this.logger.report(managesPagesModalTabNavigation({ ...this.baseProps, tabName }));
  };

  membersProfileSelection = ({ value }: Partial<membersProfileSelectionParams>) => {
    return this.logger.report(membersProfileSelection({ ...this.baseProps, value }));
  };
}

export { createBIService, BIService };
