import { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import * as applicationState from '../../applicationState';
import { removeBrokenInstallation } from './removeBrokenInstallation';
import { log } from '../../../utils/monitoring';

export async function maybeRemoveLeftoversFromUnsuccessfulInstallation(
  editorSDK: EditorSDK,
  options: EditorReadyOptions,
) {
  const isEmpty = await applicationState.isEmpty(editorSDK);
  const isReady = await applicationState.isApplicationReady(editorSDK, { shouldLog: false });
  if (options.firstInstall && !isEmpty && !isReady) {
    const state = await applicationState.getApplicationComponents(editorSDK);
    await removeBrokenInstallation(editorSDK, false);
    log('Removing leftover components from previous installations', { extra: { state: JSON.stringify(state) } });
  }
}
