import { EditorSDK } from '@wix/platform-editor-sdk';
import { HttpClient } from '@wix/http-client';
import { APP_TOKEN } from '../editor-app-module/constants';

export const getHttpClient = async (editorSDK: EditorSDK) => {
  const MEMBERS_AREA_API_URL = 'https://members.wixapps.net/_api/members-area/_api';
  const instance = await editorSDK.document.info.getAppInstance(APP_TOKEN);
  const httpClient = new HttpClient({ getAppToken: () => instance, baseURL: MEMBERS_AREA_API_URL });

  return httpClient;
};
