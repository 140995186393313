import { MA_APP_IDS } from '@wix/members-area-app-definitions';
import { WIX_BLOG } from '@wix/app-definition-ids';
import { getMembersAreaRouters, getRouterPatternDataByPageId } from './routers';
import { getRouterRefByPrefix, updateRouterConfig } from '../wrappers/routers';
import { getOnlyInstalledVerticals } from './integration';
import { RouterPattern } from '../../types/EditorAppModule';

export const MY_ACCOUNT_APP_DEF_ID = '14cffd81-5215-0a7f-22f8-074b0e2401fb';

export enum ProfileType {
  ALL = 'all',
  BWP_ONLY = 'bwp-only',
  BWP_ALL = 'bwp-all',
}

const BLOG_WRITER_ROLES = ['admin', 'blog_writer', 'blog_editor'];
const BLOG_WRITER_ROLE = 'blog_writer';
const ABOUT_PAGE_ID = MA_APP_IDS.ABOUT.pageId;

const omit = <T, K extends keyof T>(value: T, key: K): Omit<T, K> => {
  const { [key]: _, ...rest } = value;
  return rest;
};

export const hasOnlyBlogProfile = async (editorSDK) => {
  const allApps = await getOnlyInstalledVerticals(editorSDK);
  return (
    allApps[WIX_BLOG] &&
    Object.values(omit(allApps, MY_ACCOUNT_APP_DEF_ID))
      .flat()
      .filter((app) => app.pageId === ABOUT_PAGE_ID).length === 1
  );
};

const getAboutPageAppData = async (editorSDK): Promise<RouterPattern['appData'] | undefined> => {
  const { publicRouter } = await getMembersAreaRouters(editorSDK);
  const aboutPageConfig = getRouterPatternDataByPageId(publicRouter, ABOUT_PAGE_ID);
  return aboutPageConfig?.appData;
};

const updateProfilePageRouterData = async (editorSDK, appData: RouterPattern['appData']): Promise<void> => {
  const { publicRouter } = await getMembersAreaRouters(editorSDK);
  const aboutPageConfig = getRouterPatternDataByPageId(publicRouter, ABOUT_PAGE_ID);
  // TODO: Possible issue - aboutPageConfig might be undefined
  // @ts-expect-error
  aboutPageConfig.appData = appData;
  const routerRef = await getRouterRefByPrefix({ editorSDK, prefix: publicRouter.prefix });
  await updateRouterConfig({ editorSDK, config: publicRouter.config, routerRef });
};

export const getProfileType = async (editorSDK): Promise<ProfileType> => {
  let result = ProfileType.ALL;
  const aboutPage = await getAboutPageAppData(editorSDK);
  if (aboutPage && (await hasOnlyBlogProfile(editorSDK))) {
    result = aboutPage.visibleForRoles?.includes(BLOG_WRITER_ROLE) ? ProfileType.BWP_ONLY : ProfileType.BWP_ALL;
  }
  return result;
};

export const setProfileType = async (editorSDK, newType: ProfileType.BWP_ALL | ProfileType.BWP_ONLY): Promise<void> => {
  const aboutPage = await getAboutPageAppData(editorSDK);
  if (aboutPage) {
    aboutPage.visibleForRoles = aboutPage.visibleForRoles ?? [];
    if (newType === ProfileType.BWP_ONLY) {
      aboutPage.visibleForRoles = aboutPage.visibleForRoles
        .filter((role) => !BLOG_WRITER_ROLES.includes(role))
        .concat(BLOG_WRITER_ROLES);
    } else if (newType === ProfileType.BWP_ALL) {
      aboutPage.visibleForRoles = aboutPage.visibleForRoles.filter((role) => !BLOG_WRITER_ROLES.includes(role));
    }
    await updateProfilePageRouterData(editorSDK, aboutPage);
  }
};

export const resetRolesOnSocialVertical = async (editorSDK, verticalAppDefId, newApplications) => {
  const integratedApps = await getOnlyInstalledVerticals(editorSDK);
  if (
    integratedApps[WIX_BLOG] &&
    verticalAppDefId !== WIX_BLOG &&
    newApplications.some((app) => app.pageId === ABOUT_PAGE_ID)
  ) {
    const aboutPage = await getAboutPageAppData(editorSDK);
    if (aboutPage?.visibleForRoles?.length) {
      await setProfileType(editorSDK, ProfileType.BWP_ALL);
    }
  }
};
