import { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import * as applicationState from '../../applicationState';
import { log } from '../../../utils/monitoring';
import { removeBrokenInstallation } from './removeBrokenInstallation';

export async function maybeRemoveEmptyInstallation(editorSDK: EditorSDK, options: EditorReadyOptions) {
  const isEmpty = await applicationState.isEmpty(editorSDK);
  if (!options.firstInstall && isEmpty) {
    log('Removing components for empty installation as it will not install anyway and will surely fail');
    await removeBrokenInstallation(editorSDK, false);
    return { shouldContinueInitialization: false };
  }
  return { shouldContinueInitialization: true };
}
