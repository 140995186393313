import _ from 'lodash';
import * as routersService from './routers';
import * as menusWrapper from '../wrappers/menus';
import { DEFAULT_MENU_ORDER, MENU_IDS } from '../constants';
import { log } from '../../utils/monitoring';
import { getIsADI, getIsResponsiveEditor } from './applicationState';
import { BasicMenuItem, DynamicPageLink, EditorSDK, MenuItem, RouterData } from '@wix/platform-editor-sdk';
import { MembersPage, MembersPageData } from '../../types/EditorAppModule';

const { SUB_MENU_ID, LOGIN_MENU_ID, LOGIN_ICONS_MENU_ID } = MENU_IDS;

function getMembersAreaMenuIds() {
  return {
    members: SUB_MENU_ID,
    login: LOGIN_MENU_ID,
    icons: LOGIN_ICONS_MENU_ID,
  };
}

// We have issues when menus are storing pointers to pages which does not exist
// As we don't know the root cause, this should clear such menu items so further actions don't crash
// From additional information in sentry we might find out the root cause
const isInOneOfTheRouters = ({
  item,
  privateRouter,
  publicRouter,
}: {
  item: MenuItem;
  privateRouter: RouterData;
  publicRouter: RouterData;
}) => {
  const publicPatterns = publicRouter.config.patterns || {};
  const privatePatterns = privateRouter.config.patterns || {};
  return (
    !!publicPatterns[`/${(item.link as DynamicPageLink)?.innerRoute}`] ||
    !!privatePatterns[`/${(item.link as DynamicPageLink)?.innerRoute}`]
  );
};

const removeMenuItemsWithoutRouter = (
  menuId: string,
  allMenuItems: MenuItem[],
  privateRouter: RouterData,
  publicRouter: RouterData,
) => {
  const validMenuItems = allMenuItems.filter((item) => isInOneOfTheRouters({ item, privateRouter, publicRouter }));
  if (validMenuItems.length < allMenuItems.length) {
    const invalidItems = allMenuItems.filter((item) => validMenuItems.indexOf(item) < 0);
    const invalidItemsNames = invalidItems.map((i) => i.label).join(', ');
    log(`Fixing menu ${menuId}: removing items which are not apparent in any router`, {
      tags: { removedItems: invalidItemsNames },
    });
  }
  return validMenuItems;
};

// We have issues when two menu items, pointing to the same page are apparent
// Removing those duplications as we don't know the root cause...
const removeMenuItemsDuplications = ({ items, menuId }: { menuId: string; items: MenuItem[] }) => {
  const newItems: MenuItem[] = [];
  items.forEach((item) => {
    const hasNoDuplicates = !newItems.find(
      (newItem) => (newItem.link as DynamicPageLink).innerRoute === (item.link as DynamicPageLink).innerRoute,
    );
    if (hasNoDuplicates) {
      newItems.push(item);
    } else {
      log(`Fixing menu ${menuId}: removing item duplication`, { tags: { removedItem: item.label } });
    }
  });
  return newItems;
};

async function maybeCleanUpMenus(editorSDK: EditorSDK) {
  // Menu manager is currently enabled if there is a default menu
  const isMenuManagerEnabled =
    editorSDK.document.menu.getDefaultMenuId && !!(await menusWrapper.getDefaultMenuId({ editorSDK }));

  // We want to disable fixers when menus are editable from UI to not contradict with Menu Manager features
  // Horizontal menu should only be fixed in ADI as other editors allows editing it
  // Login menu should be fixed in ADI and EditorX as only Classic allows editing it
  // Icons menu should always be fixed because it is not editable
  // To do: remove fixing of Login Menu in EditorX when it is editable
  // To do: understand how the bug which needs fixing is happening
  const shouldDisableHorizontalMenuFixing = isMenuManagerEnabled && !getIsADI();
  const shouldDisableLoginMenuFixing = isMenuManagerEnabled && !getIsResponsiveEditor() && !getIsADI();

  return cleanUpMenus({ editorSDK, shouldDisableHorizontalMenuFixing, shouldDisableLoginMenuFixing });
}

async function cleanUpMenus({
  editorSDK,
  shouldDisableHorizontalMenuFixing,
  shouldDisableLoginMenuFixing,
}: {
  editorSDK: EditorSDK;
  shouldDisableHorizontalMenuFixing: boolean;
  shouldDisableLoginMenuFixing: boolean;
}) {
  const [{ publicRouter, privateRouter }, membersMenuItems, loginMenuItems, iconsMenuItems] = await Promise.all([
    routersService.getMembersAreaRouters(editorSDK),
    menusWrapper.getMenuItems({ editorSDK, menuId: SUB_MENU_ID }),
    menusWrapper.getMenuItems({ editorSDK, menuId: LOGIN_MENU_ID }),
    menusWrapper.getMenuItems({ editorSDK, menuId: LOGIN_ICONS_MENU_ID }),
  ]);

  const membersMenuItemsInRouter = shouldDisableHorizontalMenuFixing
    ? membersMenuItems
    : removeMenuItemsWithoutRouter(SUB_MENU_ID, membersMenuItems, privateRouter, publicRouter);

  const loginMenuItemsInRouter = shouldDisableLoginMenuFixing
    ? loginMenuItems
    : removeMenuItemsWithoutRouter(LOGIN_MENU_ID, loginMenuItems, privateRouter, publicRouter);

  const iconsMenuItemsInRouter = removeMenuItemsWithoutRouter(
    LOGIN_ICONS_MENU_ID,
    iconsMenuItems,
    privateRouter,
    publicRouter,
  );

  const validMembersMenuItems = shouldDisableHorizontalMenuFixing
    ? membersMenuItemsInRouter
    : removeMenuItemsDuplications({ items: membersMenuItemsInRouter, menuId: SUB_MENU_ID });

  const validLoginMenuItems = shouldDisableLoginMenuFixing
    ? loginMenuItemsInRouter
    : removeMenuItemsDuplications({ items: loginMenuItemsInRouter, menuId: LOGIN_MENU_ID });

  const validIconsMenuItems = removeMenuItemsDuplications({
    items: iconsMenuItemsInRouter,
    menuId: LOGIN_ICONS_MENU_ID,
  });

  const promises: Promise<void>[] = [];

  if (validMembersMenuItems.length < membersMenuItems.length) {
    promises.push(menusWrapper.updateMenuItems({ editorSDK, menuId: SUB_MENU_ID, items: validMembersMenuItems }));
  }

  if (validLoginMenuItems.length < loginMenuItems.length) {
    promises.push(menusWrapper.updateMenuItems({ editorSDK, menuId: LOGIN_MENU_ID, items: validLoginMenuItems }));
  }

  if (validIconsMenuItems.length < iconsMenuItems.length) {
    promises.push(menusWrapper.updateMenuItems({ editorSDK, menuId: LOGIN_ICONS_MENU_ID, items: validIconsMenuItems }));
  }

  if (promises.length > 0) {
    await Promise.all(promises);
  }
}

function createMenuItem({
  page,
  urlOverride,
  publicRouter,
  privateRouter,
}: {
  page: { pageData: Pick<MembersPageData, 'isPrivate' | 'title' | 'pageUriSEO'> };
  privateRouter: RouterData;
  publicRouter: RouterData;
  urlOverride?: string | null;
}) {
  const { pageData } = page;
  const routerId = page.pageData.isPrivate ? privateRouter.id : publicRouter.id;

  let innerRoute = urlOverride || pageData.pageUriSEO;
  innerRoute = pageData.isPrivate ? innerRoute : '{userName}/' + innerRoute;

  const link = { type: 'DynamicPageLink', routerId, innerRoute };
  return { type: 'BasicMenuItem', items: [], label: pageData.title, link } as Partial<BasicMenuItem>;
}

function createMembersMenuItems({
  pages,
  publicRouter,
  privateRouter,
}: {
  pages: MembersPage[];
  privateRouter: RouterData;
  publicRouter: RouterData;
}) {
  return pages
    .filter((p) => p.showInMemberMenu || typeof p.showInMemberMenu === 'undefined')
    .map((page) => {
      return createMenuItem({ page, urlOverride: page.urlOverride, publicRouter, privateRouter });
    });
}

function createLoginMenuItems({
  pages,
  publicRouter,
  privateRouter,
}: {
  pages: MembersPage[];
  privateRouter: RouterData;
  publicRouter: RouterData;
}) {
  return pages
    .filter((p) => p.showInLoginMenu)
    .map((page) => {
      const menuItem = createMenuItem({ page, urlOverride: page.urlOverride, publicRouter, privateRouter });
      return {
        ...menuItem,
        label: page.loginMenuTitle || page.pageData.title,
      };
    });
}

function createIconsMenuItems({
  pages,
  publicRouter,
  privateRouter,
}: {
  pages: MembersPage[];
  privateRouter: RouterData;
  publicRouter: RouterData;
}) {
  return pages
    .filter((p) => p.showInIconsMenu)
    .map((page) => {
      const menuItem = createMenuItem({ page, urlOverride: page.urlOverride, publicRouter, privateRouter });
      return {
        ...menuItem,
        iconRef: { svgId: '2c36dc006cb94853a49daee7e821f642.svg', type: 'VectorImage' },
      };
    });
}

function sortMenuItems({
  menuItems,
  publicRouter,
  privateRouter,
}: {
  menuItems: MenuItem[];
  privateRouter: RouterData;
  publicRouter: RouterData;
}): MenuItem[] {
  [publicRouter, privateRouter].forEach((router) => {
    return (
      router.config &&
      router.config.patterns &&
      Object.keys(router.config.patterns).forEach((pattern) => {
        const menuItem = menuItems
          .filter(
            (item) =>
              (item.link as DynamicPageLink)?.routerId === router.id &&
              `/${(item.link as DynamicPageLink).innerRoute}` === pattern,
          )
          .pop();
        if (menuItem) {
          // @ts-expect-error - TODO: missing prop 'order' in platform editor sdk menuItem types
          menuItem.order = router.config.patterns[pattern].appData.menuOrder || DEFAULT_MENU_ORDER;
        }
      })
    );
  });

  // @ts-expect-error missing prop 'order' in platform editor sdk menuItem types
  return _.sortBy(menuItems, (item) => item.order || DEFAULT_MENU_ORDER);
}

async function connectPagesToMenus({ editorSDK, pages }: { editorSDK: EditorSDK; pages: MembersPage[] }) {
  const [{ publicRouter, privateRouter }, oldMembersMenuItems, oldLoginMenuItems, oldIconsMenuItems] =
    await Promise.all([
      routersService.getMembersAreaRouters(editorSDK),
      menusWrapper.getMenuItems({ editorSDK, menuId: SUB_MENU_ID }),
      menusWrapper.getMenuItems({ editorSDK, menuId: LOGIN_MENU_ID }),
      menusWrapper.getMenuItems({ editorSDK, menuId: LOGIN_ICONS_MENU_ID }),
    ]);

  const newMembersMenuItems = createMembersMenuItems({ pages, publicRouter, privateRouter });
  const newLoginMenuItems = createLoginMenuItems({ pages, publicRouter, privateRouter });
  const newIconsMenuItems = createIconsMenuItems({ pages, publicRouter, privateRouter });

  const hasNewMembersMenuItems = newMembersMenuItems.length > 0;
  const hasNewLoginMenuItems = newLoginMenuItems.length > 0;
  const hasNewIconsMenuItems = newIconsMenuItems.length > 0;

  const sortedMembersMenuItems = sortMenuItems({
    // TODO: Possible issue as menu item types are mismatched
    menuItems: oldMembersMenuItems.concat(newMembersMenuItems as unknown as MenuItem),
    publicRouter,
    privateRouter,
  });
  const sortedLoginMenuItems = sortMenuItems({
    menuItems: oldLoginMenuItems.concat(newLoginMenuItems as unknown as MenuItem),
    publicRouter,
    privateRouter,
  });
  const sortedIconsMenuItems = sortMenuItems({
    menuItems: oldIconsMenuItems.concat(newIconsMenuItems as unknown as MenuItem),
    publicRouter,
    privateRouter,
  });

  return Promise.all(
    [
      hasNewMembersMenuItems &&
        menusWrapper.updateMenuItems({ editorSDK, menuId: SUB_MENU_ID, items: sortedMembersMenuItems }),
      hasNewLoginMenuItems &&
        menusWrapper.updateMenuItems({ editorSDK, menuId: LOGIN_MENU_ID, items: sortedLoginMenuItems }),
      hasNewIconsMenuItems &&
        menusWrapper.updateMenuItems({ editorSDK, menuId: LOGIN_ICONS_MENU_ID, items: sortedIconsMenuItems }),
    ].filter((p) => !!p) as Promise<void>[],
  );
}

export { createMenuItem, connectPagesToMenus, getMembersAreaMenuIds, maybeCleanUpMenus, cleanUpMenus };
